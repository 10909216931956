/* LandingSection.css */

.landing-section {
    text-align: center;
    width: 100%;
}

.landing-section img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

@media only screen and (min-width: 600px) {
    .landing-section img {
        height: auto;
    }
}
