.responsive {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

.swiper {
    z-index: 0 !important;
}

.swiper-thumbs {
    width: 80%
}

.thumb-wrapper {
    padding: 4px;
}

.product-wrapper {
    display: flex;
    padding-top: 32px;
}

.product-imgs {
    width: 50%;    
}

.product-description {
    width: '50%';
    padding-left: '24px';
}

.icon__link {
    font-size: 2rem;
    color: #e21919;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

@media only screen and (max-width: 960px) {  
    .product-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .hidden {
        display: none;
    }

    .product-imgs {
        width: 100%;
    }

    .product-description {
        width: 100%;
    }
}