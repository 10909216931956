body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:rgb(238, 238, 238);
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}

.shop {
    max-width: 120px;
    margin: 0 auto;
    padding: 20px;
}

.product-list {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

@media only screen and (max-width: 960px) {
    .product-list {
        padding: 5px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
}