.nav-wrapper {
    width: 100%;
    background-color: whitesmoke;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-height: 100px;
    left: 50px;
    position: relative;
    z-index: 1;
}

.nav-item {
    color: black;
}

.home-nav {
    top: 10px;
    left: 50px;
}

.home-nav a {
    color: rgb(255, 255, 255, 0.7);
}
.logo-container {
    width: 20%;
}
.logo {
    max-width: 100%;
    height: auto;
}

.nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.nav li {
    margin-left: 50px;
}

a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.burger {
    display: none;
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: lightgray;
    margin: 5px;
    transition: all 0.3s ease;
}

.toggleBurger .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggleBurger .line2 {
    opacity: 0;
}

.toggleBurger .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}

@media only screen and (max-width: 960px) {
    .nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 94vh;
        position: absolute;
        top: 80px;
        left: -100%;
        transition: all 0.5s ease;
    }

    .nav.active {
        align-items: center;
        background: lightgray;
        left: -25%;
        gap: 32px;
        width: 75%;
    }

    .burger {
        display: block;
        padding-right: 32px;
        cursor: pointer;
    }
}
